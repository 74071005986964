import request from '@/utils/axios'

// 列表条件
// export function getOreder (params) {
//   return request({
//     url: '/api/auth/user-infor',
//     method: 'get',
//     params
//   })
// }

// 订单详情
export function orderDetail (data) {
  return request({
    url: '/api/order/orderDetail',
    method: 'post',
    data
  })
}

// 订单列表
export function getOreder (data) {
  return request({
    url: '/api/order/orderList',
    method: 'post',
    data
  })
}

// 确认匹配
export function updataOreder (data) {
  return request({
    url: '/api/order/matchingHs',
    method: 'post',
    data
  })
}


// 取消订单
export function cancelOreder (data) {
  return request({
    url: '/api/order/cancelOrder',
    method: 'post',
    data
  })
}

//  获取服务和节点
export function getBbjdOreder (data) {
  return request({
    url: '/api/overseasTeacher/getBbjd',
    method: 'post',
    data
  })
}

//  提交预约课程
export function addCoursesOrder (data) {
  return request({
    url: '/api/coursesOrder/addCoursesOrder',
    method: 'post',
    data
  })
}

// 服务评价
export function getEvalutionList (data) {
  return request({
    url: '/api/order/evaluationList',
    method: 'post',
    data
  })
}

// 评价详情
export function getOrderEvaluation (data) {
  return request({
    url: '/api/order/orderEvaluation',
    method: 'post',
    data
  })
}
// 发布评价
export function addEvaluation (data) {
  return request({
    url: '/api/order/addEvaluation',
    method: 'post',
    data
  })
}

// 查询预约课程
export function coursesOrderList (data) {
  return request({
    url: '/api/coursesOrder/coursesOrderList',
    method: 'post',
    data
  })
}
// 确认课程单价
export function confirmPrice (data) {
  return request({
    url: '/api/coursesOrder/confirmPrice',
    method: 'post',
    data
  })
}
// 查询预约课程
export function endCourse (data) {
  return request({
    url: '/api/coursesOrder/endCourse',
    method: 'post',
    data
  })
}
// 上传文件2
export function upfile2 (data) {
  return request({
    url: '/api/coursesOrder/uploadAttachment',
    method: 'post',
    data,
  })
}

// 更改时间
export function changeCourseTime (data) {
  return request({
    url: '/api/coursesOrder/changeCourseTime',
    method: 'post',
    data
  })
}

// 完成订单
export function completeOrder (data) {
  return request({
    url: '/api/order/completeOrder',
    method: 'post',
    data
  })
}
