<template>
  <div class="service">
    <div class="service_content">
      <div class="content">
        <!-- 顶部面包屑 s -->
        <bread-crumb :nav_title="nav_title" />
        <!-- 顶部面包屑 end-->

        <!-- 服务节点 -->
        <div class="complete_evalution">
          <div class="service_bg">
            <div class="service_jiedian">
              <el-steps
                :space="(7 - order.schedule.progress_code) * 200"
                :active="6"
                finish-status="success"
                align-center
              >
                <el-step
                  title="提交预约"
                  v-if="order.schedule.progress_code >= 1"
                ></el-step>
                <el-step
                  title="已匹配"
                  v-if="order.schedule.progress_code >= 2"
                ></el-step>
                <el-step
                  title="全部结束"
                  v-if="order.schedule.progress_code >= 3"
                ></el-step>
                <el-step
                  title="全部结算"
                  v-if="
                    order.info.is_settlement == 1 &&
                    order.schedule.progress_code >= 3
                      ? '已全部结算'
                      : '未结算'
                  "
                ></el-step>
                <el-step
                  title="已评价"
                  v-if="order.schedule.progress_code >= 5"
                ></el-step>
                <el-step
                  title="完成"
                  v-if="order.schedule.progress_code >= 6"
                ></el-step>
              </el-steps>
            </div>
          </div>
        </div>
        <!-- 订单详情 title-->
        <div class="service_details">
          <div class="no_evalution_title">
            <tr>
              <th style="width: 364px">订单详情</th>
              <th style="width: 116px">学生姓名</th>
              <th style="width: 181px">手机号码</th>
              <th style="width: 195px">合同编号</th>
              <th style="width: 175px">课程状态</th>
              <th style="width: 124px">付款状态</th>
            </tr>
          </div>
          <!-- 订单表格 -->
          <div class="service_nums">
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td
                  colspan="6"
                  style="height: 30px; text-align: left; padding-left: 20px"
                >
                  订单编号：<span>{{ order.schedule.order_sn }}</span>
                </td>
              </tr>
              <tr>
                <td style="width: 364px">
                  <div class="no_evalution_users">
                    <div class="users_info">
                      <el-avatar
                        src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                      />
                      <div class="users_detail">
                        <span>{{ order.info.teacher_name }}</span>
                        <p>{{ order.info.hs_service_name }}</p>
                      </div>
                    </div>
                    <div class="yuyue_times">
                      预约时间：<span>{{ order.info.created_at }}</span>
                    </div>
                  </div>
                </td>
                <td style="width: 116px">{{ order.info.user_name }}</td>
                <td style="width: 181px">{{ order.info.hd_sjh }}</td>
                <td style="width: 195px">{{ order.info.contract_number }}</td>
                <td class="overs" style="width: 175px">
                  {{ order.info.is_evaluation == 1 ? "已评价" : "未评价" }}
                </td>
                <td class="settlement" style="width: 124px">
                  {{ order.info.is_settlement == 1 ? "已全部结算" : "未结算" }}
                </td>
              </tr>
            </table>
          </div>
          <!-- 写评价 -->
          <div class="pingjia">
            <el-tabs v-model="activeName">
              <el-tab-pane label="评价海师" name="1" v-if="shows">
                <div class="haishi_score">
                  <span>总体满意度：</span>
                  <el-rate v-model="stars" :colors="colors"> </el-rate>
                </div>
                <div class="haishi_score">
                  <span> 总体满意度：</span>
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="textarea"
                  >
                  </el-input>
                </div>
              </el-tab-pane>

              <el-tab-pane label="评价学生" name="2" v-if="show">
                <div class="haishi_score">
                  <span>总体满意度：</span>
                  <el-rate v-model="stars" :colors="colors"></el-rate>
                </div>
                <div class="haishi_score">
                  <span> 总体满意度：</span>
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="textarea"
                  >
                  </el-input>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- 提交按钮 -->
          <div class="btn" @click="fb_score" v-if="show">
            <el-row>
              <el-button plain>提交</el-button>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from "../personal/components/breadcrumb";
import { getOrderEvaluation, orderDetail, addEvaluation } from "@/api/order.js";

export default {
  data() {
    return {
      value1: 5,
      // value2: null,
      activeName: "1",
      colors: ["#F3BC70", "#F3BC70", "#F3BC70"],
      stars: null,
      // show-text
      // texts:['差','还需努力','加油','很好','非常棒'],
      textarea: "",
      type: 1,
      shows: true,
      show: true,
      order: {
        info: {},
        schedule: {},
      },
      scores: "",
      id: "",
      nav_title: [
        { name: "订单中心" },
        { name: "服务评价" },
        { name: "写评价" },
      ],
    };
  },
  components: {
    breadCrumb: breadCrumb,
  },
  methods: {
    Init() {
      let id = this.$route.query.id;
      console.log(11221, this.$route.query);
      this.type = this.$route.query.type;
      getOrderEvaluation({ id }).then((res) => {
        this.scores = res.data;
      });
      orderDetail({ id }).then((res) => {
        console.log(2222, res.data);
        this.order = res.data;
      });
    },
    //发布评价
    fb_score() {
      let obj = {
        id: this.id,
        star: this.stars,
        content: this.textarea,
        type: this.activeName,
      };
      // 评价海师和学生切换隐藏
      addEvaluation(obj).then((res) => {
      this.adds = res.data;
      if (res.code == 200) {
      this.$message({
          message: '评价成功',
          type: 'success'
        });
      if (this.activeName == 1) {
      // this.$router.push(`/evalution?orderid=${this.id,this.type}`);
      this.shows = false;
      this.activeName = "2";
      this.stars = "";
      this.textarea = "";
      } else if (this.activeName == 2) {
      this.show = false;
      this.activeName = "1";
      this.stars = "";
      this.textarea = "";
      // this.$router.push(`/evalution?orderid=${this.id,this.type}`);
      }
      }
      });
    },
  },
  mounted() {
    this.Init();
    this.id = this.$route.query.id;
  },
};
</script>

<style lang="scss" scoped>
.service {
  background: #f5f5f5;
  padding-bottom: 40px;
  .service_content {
    margin: 0 auto;
    width: 1200px;

    // 服务节点
    .service_bg {
      height: 215px;
      background: url("../personal/images/bjt.png") no-repeat center;
      .service_jiedian {
        padding: 76px 107px 75px 117px;
        /deep/.el-step__icon {
          width: 35px;
          height: 35px;
          border: 5px solid #ededed;
          color: #ffffff;
          background: #009349;
        }
        /deep/ .el-step.is-horizontal .el-step__line {
          height: 4px;
          top: 16px;
          background: #009349;
          .el-step__line-inner {
            border-style: none;
          }
        }
        /deep/.el-step__title.is-success {
          font-size: 14px;
          font-weight: 500;
          color: #666666;
        }
      }
    }
    // 评价详情
    .service_details {
      background: #ffffff;
      border-radius: 8px;
      padding: 20px;
      padding-bottom: 40px;
      margin-top: 20px;
      .no_evalution_title {
        width: 1160px;
        height: 50px;
        background: #f5f5f5;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 500;
        color: #666666;
        line-height: 50px;
        text-align: center;
      }
      // 订单详情
      .service_nums {
        margin-top: 20px;
        width: 1160px;
        height: 133px;
        border-radius: 4px;
        border: 1px solid #ededed;
        text-align: center;

        .no_number {
          // width: 950px;
          height: 30px;
          background: #fcfcfc;
          border-radius: 4px 4px 0px 0px;
          font-size: 13px;
          font-weight: 400;
          color: #666666;
          padding-left: 20px;
          border: none;
          text-align: left;
        }
        td {
          border-top: 1px solid #ededed;
          font-size: 13px;
          font-weight: 400;
          color: #333333;
          border-right: 1px solid #ededed;
          height: 102px;
          // text-align: center;
        }
        .no_evalution_users {
          text-align: left;

          .users_info {
            display: flex;
            padding-left: 20px;
            flex-wrap: wrap;
            .users_detail {
              margin-left: 5px;
              p {
                margin-top: 11px;
              }
            }
          }
          .yuyue_times {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            margin-top: 14px;
            margin-left: 20px;
            span {
              color: #666666;
            }
          }
        }
        .no_evalution_caozuo {
          border-right: none;
        }
        /deep/ .el-tag {
          height: 26px;
          border: 1px solid rgba(24, 144, 255, 0.5);
          // text-align: center;
          display: block;
          margin: auto;
          margin-top: 10px;
        }
        .settlement {
          color: #666666;
        }
        .overs {
          color: #666666;
        }
      }
      // 评价
      .pingjia {
        margin-top: 20px;
        .haishi_score {
          display: flex;
          margin-top: 40px;
          span {
            margin-right: 30px;
            color: #999999;
          }
        }
        // 表头
        /deep/ .el-tabs__nav-wrap {
          height: 70px;
          line-height: 70px;
          background: #fcfcfc;
          .el-tabs__active-bar {
            width: 70px;
            height: 4px;
            border-radius: 2px;
            background-color: #d99d66;
          }
          .el-tabs__item {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
          .el-tabs__item.is-active {
            font-size: 18px;
            font-weight: bold;
            color: #d99d66;
          }
        }
        // 表头下划线隐藏
        /deep/.el-tabs__nav-wrap::after {
          background-color: transparent !important;
        }
        // 文本区域框
        /deep/.el-textarea {
          width: 818px;
        }
        /deep/.el-textarea__inner {
          width: 818px;
          height: 160px;
          background: #fcfcfc;
          border-radius: 4px;
          border: 1px solid #ededed;
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          line-height: 24px;
        }
      }
      // 提交
      /deep/ .el-button {
        width: 350px;
        height: 50px;
        background: linear-gradient(90deg, #f8e2c4 0%, #f3bc70 100%);
        border-radius: 4px;
        border: none;
        margin-left: 125px;
        margin-top: 40px;
        font-size: 16px;
        font-weight: 500;
        color: #935d14;
      }
    }
  }
}
</style>
